<template>
  <div class="bg-gray-main py-16 mb-16">
    <ValidationObserver v-slot="{ invalid }" ref="salaryForm">
      <form @submit.prevent="submitSalary">
        <div class="container">
          <header class="mb-4">
            <h2 class="text-3xl font-bold">Add Salary</h2>
            <p>
              You are helping the entire community by adding your salary,
              changing the culture around salary transparency.
            </p>
          </header>
          <div class="mt-16">
            <div class="shadow p-8 bg-white mt-8">
              <h4 class="text-xl font-bold">Company Information</h4>
              <div class="grid md:grid-cols-2 gap-6 mt-6">
                <div>
                  <TextBox
                    id="companyName"
                    type="text"
                    label="Name"
                    rules="required"
                    v-model="salaryForm.companyName"
                  />
                </div>
                <div>
                  <Select
                    id="levelsSelect"
                    :options="levelsOptions"
                    rules="required"
                    v-model="salaryForm.companyLevel"
                    label="Level"
                  />
                </div>
              </div>
            </div>
            <div class="shadow p-8 bg-white mt-8">
              <h4 class="text-xl font-bold">Details and Experience</h4>
              <div class="grid md:grid-cols-2 gap-6 mt-6 mb-4">
                <div>
                  <TextBox
                    id="jobTitle"
                    type="text"
                    rules="required"
                    v-model="salaryForm.jobTitle"
                    label="Title"
                    placeholder="e.g. Network Engineer"
                  />
                </div>
                <div>
                  <SelectOptGroup
                    id="jobFamily"
                    :options="jobsOptions"
                    v-model="salaryForm.jobFamily"
                    label="Job Family"
                  />
                </div>
              </div>
              <div class="grid md:grid-cols-2 gap-6 mt-4 md:mt-0 mb-4">
                <div class="mt-4 md:mt-0">
                  <TextBox
                    id="totalExp"
                    rules="required"
                    type="number"
                    label="Years of Experience"
                    placeholder="e.g. 5"
                    v-model="salaryForm.totalExp"
                  />
                </div>
                <div>
                  <TextBox
                    id="expAtCompany"
                    rules="required"
                    type="number"
                    label="Years at Company"
                    placeholder="e.g. 3"
                    v-model="salaryForm.expAtCompany"
                  />
                </div>
              </div>
              <div class="mt-4 md:mt-0">
                <TextBox
                  id="jobLocation"
                  rules="required"
                  type="text"
                  label="Location"
                  placeholder="e.g. Lagos, Nigeria"
                  v-model="salaryForm.jobLocation"
                />
              </div>
            </div>
            <div class="shadow p-8 bg-white mt-8">
              <header>
                <h4 class="text-xl font-bold">Compensation</h4>
                <small class="text-black-light"
                  >For currency conversion, check out
                  <a
                    class="text-purple underline"
                    href="https://wise.com/us/currency-converter/"
                    >this tool</a
                  ></small
                >
              </header>
              <div class="my-4">
                <Select
                  id="genderSelect"
                  v-model="salaryForm.currency"
                  :options="currencyOptions"
                  label="Choose Currency"
                  :required="false"
                />
              </div>
              <div class="gap-6 mt-6">
                <div class="gap-6">
                  <div class="grid md:grid-cols-2 gap-6 mb-4">
                    <div>
                      <TextBox
                        label="Salary"
                        type="number"
                        id="salaryYearly"
                        placeholder="e.g. 1000"
                        rules="required"
                        v-model="salaryForm.salaryYearly"
                      />
                    </div>
                    <div>
                      <TextBox
                        label="Stock Grant"
                        type="number"
                        placeholder="e.g. 500"
                        id="stockGrantYearly"
                        v-model="salaryForm.stockGrantYearly"
                      />
                    </div>
                  </div>
                </div>
                <div class="mb-4">
                  <TextBox
                    label="Bonus"
                    type="number"
                    id="bonusYearlyAverage"
                    placeholder="e.g. 1000"
                    v-model="salaryForm.bonusYearlyAvg"
                  />
                </div>
                <div class="mt-6">
                  <TextBox
                    label="Total Yearly Compensation"
                    type="number"
                    id="totalYearlyCompensation"
                    rules="required"
                    placeholder="e.g. 2500"
                    v-model="salaryForm.totalYearlyComp"
                    subtitle="Salary + Stock + Bonus you will make this year."
                  />
                </div>
              </div>
            </div>
            <div class="shadow p-8 bg-white mt-8">
              <header class="flex items-center space-x-2">
                <h4 class="text-xl font-bold">Optional</h4>
                <small class="text-black-light">(will be displayed)</small>
              </header>
              <div class="gap-6 mt-6">
                <div>
                  <Select
                    id="genderSelect"
                    v-model="salaryForm.gender"
                    :options="genderOptions"
                    label="Gender"
                    rules="required"
                    :required="false"
                  />
                </div>
              </div>

              <div class="gap-6 mt-6">
                <div>
                  <TextBox
                    id="email"
                    type="email"
                    label="Email"
                    v-model="email"
                    :required="false"
                    subtitle="Get the latest salary report in your inbox weekly"
                  />
                </div>
              </div>
            </div>
            <div class="mt-16 flex items-center justify-center">
              <Button
                :loading="formSubmitting"
                type="submit"
                :disabled="invalid"
                text="Add Salary"
              />
            </div>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { http } from "@/helpers/http";
import Select from "@/components/core/Select";
import Button from "@/components/core/Button";
import TextBox from "@/components/core/TextBox";
import MessagePrompt from "@/components/core/MessagePrompt";
import SelectOptGroup from "@/components/core/SelectOptGroup";
import {
  getJobFamilies,
  getGender,
  getLevels,
  getCurrencies,
} from "@/helpers/enums";

export default {
  name: "AddSalary",
  components: { TextBox, Select, Button, SelectOptGroup, MessagePrompt },
  created() {
    let companyNameQuery = this.$route.query.companyName;

    if (companyNameQuery) this.salaryForm.companyName = companyNameQuery;
  },
  mounted() {
    // Populate selects
    this.jobsOptions = getJobFamilies();
    this.genderOptions = getGender();
    this.levelsOptions = getLevels();
    this.currencyOptions = getCurrencies();

    // Scroll to top
    window.scrollTo(0, 0);
  },
  data() {
    return {
      salaryForm: {
        companyName: null,
        companyLevel: null,
        companyId: null,
        salaryYearly: null,
        stockGrantYearly: null,
        totalYearlyComp: null,
        bonusYearlyAvg: null,
        jobTitle: null,
        jobFamily: null,
        tagFocus: null,
        expAtCompany: null,
        jobLocation: null,
        isRemote: null,
        gender: null,
        race: null,
        highestAcdLevel: null,
        totalExp: null,
        extraDetails: null,
        currency: null,
      },
      currencyOptions: [],
      levelsOptions: [],
      genderOptions: [],
      jobsOptions: {},
      message: "",
      email: null,
      formSubmitting: false,
    };
  },
  methods: {
    submitSalary(e) {
      this.formSubmitting = true;

      // Submit Salary
      http
        .post("/api/user/salaries", this.salaryForm, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          // Email
          this.submitEmail();

          // Submit Salary
          if (response.status == 200)
            this.$swal({
              title: "Salary Submitted",
              html: this.swalHtml(),
              text: "Thank you for your contribution to the effort to make salary cultures transparent.",
              icon: "success",
              confirmButtonText: "Go to Home",
            }).then((result) => {
              if (result.isConfirmed) this.$router.push({ name: "Home" });
            });

          this.resetForm();
          this.formSubmitting = false;
        })
        .catch((error) => {
          console.log(error);
          this.message = error.message;
          this.formSubmitting = false;
        });
    },
    resetForm() {
      Object.keys(this.salaryForm).forEach((i) => (this.salaryForm[i] = null));
    },
    submitEmail() {
      if (!this.email) return;

      let emailData = { email: this.email };

      let config = {
        method: "post",
        url: "/api/user/subscriptions",
        headers: {
          "Content-Type": "application/json",
        },
        data: emailData,
      };

      http(config);
    },
    swalSocialButtons() {
      let buttons = "";
      buttons += `<div class="flex justify-center items-center space-y-2 md:space-y-0 md:space-x-2 flex-wrap">`;
      // Facebook
      buttons += `<a href="https://www.facebook.com/sharer/sharer.php?u=saalari.com&quote=Get%20better%20at%20negotiating%20your%20salary%20by%20having%20informed%20accurate%20salary%20data.%20Let%E2%80%99s%20put%20an%20end%20to%20pay%20injustice%20at%20the%20workplace.%20Add%20your%20salary%20information%20now.%20I%20just%20did!"
                  class="p-2 mb-2 md:mb-0 text-sm rounded bg-facebook text-white hover:text-facebook hover:bg-white border border-facebook cursor-pointer"
                  ><i class="mr-2 fab fa-facebook-f"></i> Share on Facebook</a
                >`;
      // Twitter
      buttons += `<a href="https://twitter.com/intent/tweet?text=Get better at negotiating your salary by having informed accurate salary data. Let’s put an end to pay injustice at the workplace. Add your salary information now. I just did! at saalari.com"
                  class="p-2 text-sm rounded bg-twitter text-white hover:text-twitter hover:bg-white border border-twitter cursor-pointer"
                  ><i class="mr-2 fab fa-twitter"></i>Share on Twitter</a
                >`;
      buttons += `</div>`;
      return buttons;
    },
    swalHtml() {
      let text =
        "<div class='mb-3'>Share saalari.com. We won't share your salary details.</div>";
      let socialButtons = this.swalSocialButtons();
      return text + socialButtons;
    },
  },
};
</script>

<style>
</style>